import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Select, Group, FormLabel, Button, Box, Grid, Typography, IconCaretLeft } from '@screentone/core';

import { useConfig } from '../../../hooks/useConfig';

import { fnSelectRenderer, DEFAULT_PROPERTY_CONFIG as propertiesConfig } from '../../../utils/fnSelect';
import { getImageDomain, getMD5Domain, formatBytes } from '../../../utils/helpers';
import { constants } from '../../../utils';

import type { PropertyType } from '../../../types';

const backStyles = {
  zIndex: 'var(--st-zindex-mid)',
  borderBottom: 'var(--st-border-width) solid var(--st-border-color)',
  backgroundColor: 'var(--st-page-bg)',
  padding: 'var(--st-spacer-md) var(--st-spacer-mlg) var(--st-spacer-md) var(--st-spacer-mlg)',
  marginBottom: 'var(--st-spacer-lg)',
};

function DeveloperDocs() {
  const {
    session: { env },
  } = useConfig();

  const [images, setImages] = useState<any>(null);

  useEffect(() => {
    if (!images) {
      formSubmit();
    }
  }, []);

  const properties = constants.PROPERTY_LABELS;
  const searchParams = new URLSearchParams(location.search);
  const resourceType = 'image';
  const [formData, setFormData] = useState({
    id: searchParams.get('id') || '',
    width: 287,
    height: 191,
    size: '',
  });

  const config = [
    {
      title: 'JPEG Full Quality Image',
      options: {
        pixelratio: '2',
        ext: 'jpg',
      },
      context: {
        quality: 100,
      },
    },
    {
      title: 'Auto Full Quality Image',
      options: {
        pixelratio: '2',
      },
      context: {
        quality: 100,
      },
    },
    {
      title: 'Auto Image sharpen',
      options: {
        pixelratio: '2',
      },
      context: {
        quality: 'auto:eco',
        sharpen: 'sharpen',
      },
    },
    {
      title: 'Auto Image sharpen 50',
      options: {
        pixelratio: '2',
      },
      context: {
        quality: 'auto:eco',
        sharpen: 'sharpen:50',
      },
    },
    {
      title: 'Auto Image (DPR 2)',
      options: {
        pixelratio: '2',
      },
      context: {},
    },
    {
      title: 'Auto Image (DPR 1.5)',
      options: {
        pixelratio: '1.5',
      },
      context: {},
    },
    {
      title: 'Auto Image (DPR 1)',
      options: {
        pixelratio: '1',
      },
      context: {},
    },
  ];

  const getFormattedInputs = (d: any) => {
    const { id, ...rest } = d;
    const newInputs: any = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(rest || {})) {
      if (value && value !== '') {
        newInputs.push(`$${key}_!${value}!`);
      }
    }

    return newInputs;
  };

  const [property, setProperty] = useState((searchParams.get('property') || 'mansionglobal') as PropertyType);
  const cname = getImageDomain(env, property);

  const getImageData = (src: any) => {
    const image = new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const size = { height: img.height, width: img.width };
        resolve(size);
      };
      img.onerror = resolve;
      img.src = src;
    });
    const header = fetch(src, { headers: { Accept: 'image/avif, image/*' }, mode: 'cors' }).then((response) => {
      const contentLength = response.headers.get('content-length');
      const fileSize = formatBytes(Number(contentLength));
      const error = response.headers.get('x-cld-error');
      const errorSplit = error?.split('{');
      const errorMessages = errorSplit?.[0];

      const errorJson = new Function(`return ${error?.replace(errorMessages || '', '')}`)();

      const data = {
        error: errorMessages,
        errorJson: errorJson,
        contentType: response.headers.get('content-type'),
        contentLength,
        fileSize,
      };
      // console.group('getImageData: ');
      // console.log('src: ', src);
      // console.log('data: ', JSON.stringify(data, null, 2));
      // console.groupEnd();
      return data;
    });
    return Promise.all([header, image])
      .then(([h, i]) => ({ ...h, ...(i as Object) }))
      .catch((error) => {
        console.error('getImageData error: ', error);
      });
  };

  const formChange = (field: string, value: string) => {
    setFormData({ ...formData, ...{ [field]: value } });
  };

  const formSubmit = () => {
    const md5 = getMD5Domain(env, property);

    const { id } = formData;

    const jsonUrl = `${cname}${resourceType}/list/${id}.json`;

    fetch(jsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText || 'Unknown Error');
        }
        return response.json();
      })
      .then(async (data) => {
        const listData = JSON.stringify(data.resources);
        const allImageData: any = [];

        await config.forEach(async (configItem, index) => {
          const { title, options, context } = configItem;
          const copyListData = [...JSON.parse(listData)];

          const getDocument = () => {
            copyListData[0].context.custom = { ...copyListData[0].context.custom, ...context };
            return JSON.stringify([...copyListData]);
          };
          const getContext = () => {
            const inputs = getFormattedInputs({ ...formData, ...options });
            return {
              resource: id,
              transformation: `$host_!${md5}!,${inputs.join(',')}`,
            };
          };

          const res = await fnSelectRenderer(getDocument, getContext);
          const url = `${cname}image/upload/${res.transformation}/${res.public_id}`;

          await getImageData(url).then((ImageData) => {
            const data = { ...ImageData, title, url, index };
            allImageData.push(data);
          });
        });

        const currentDpr2Url = `${cname}${formData.id}?width=${formData.width}&height=${formData.height}&pixel_ratio=2`;
        await getImageData(currentDpr2Url).then((currentImageData) => {
          const data = {
            ...currentImageData,
            title: 'Publish FN Select (DPR 2)',
            url: currentDpr2Url,
            index: allImageData.length,
          };
          allImageData.push(data);
        });

        const currentUrl = `${cname}${formData.id}?width=${formData.width}&height=${formData.height}`;
        await getImageData(currentUrl).then((currentImageData) => {
          const data = {
            ...currentImageData,
            title: 'Publish FN Select (DPR 1)',
            url: currentUrl,
            index: allImageData.length,
          };
          allImageData.push(data);
        });

        setImages(allImageData);
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  return (
    <>
      {formData.id.length > 6 && property && (
        <div style={backStyles}>
          <Group align="space-between">
            <Button icon={IconCaretLeft} tertiary componentEl={Link} to={`/${property}/image/${formData.id}`}>
              Image Details
            </Button>
          </Group>
        </div>
      )}
      <Typography variant="header1" margin={{ top: 'lg' }}>
        Quality Debugger
      </Typography>
      <Group valign="end" margin={{ bottom: 'md' }}>
        <FormLabel label="Property" labelPosition="top">
          <Select
            name="name"
            value={property}
            onChange={(event: any) => {
              setProperty(event.target.value as PropertyType);
            }}
          >
            {Object.keys(properties).map((e) => (
              <option key={e} value={e}>
                {properties[e as PropertyType]}
              </option>
            ))}
          </Select>
        </FormLabel>
        <FormLabel label="id" labelPosition="top">
          <Input value={formData.id} onChange={(event: any) => formChange('id', event.target.value)} />
        </FormLabel>
        <FormLabel label="named" labelPosition="top">
          <Select name="name" value={formData.named} onChange={(event: any) => formChange('named', event.target.value)}>
            <option value="">-- None --</option>
            <option value="preview">Preview</option>
            {Object.keys(propertiesConfig[property].SIZES).map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>
        </FormLabel>

        <FormLabel label="width" labelPosition="top" style={{ width: '70px' }}>
          <Input value={formData.width} onChange={(event: any) => formChange('width', event.target.value)} />
        </FormLabel>
        <FormLabel label="height" labelPosition="top" style={{ width: '70px' }}>
          <Input value={formData.height} onChange={(event: any) => formChange('height', event.target.value)} />
        </FormLabel>
        <FormLabel label="size" labelPosition="top" style={{ width: '70px' }}>
          <Input value={formData.size} onChange={(event: any) => formChange('size', event.target.value)} />
        </FormLabel>
        <Button primary onClick={() => formSubmit()}>
          Submit
        </Button>
      </Group>

      <Grid>
        <Grid.Row>
          {images &&
            images
              .sort((a: any, b: any) => a.index - b.index)
              .map((image: any) => (
                <Grid.Col width={4}>
                  <Box margin={{ top: 'md' }}>
                    <Typography variant="header2">{image.title}</Typography>
                    <Typography variant="body1">Content Type: {image.contentType}</Typography>
                    <Typography variant="body1">File Size: {image.fileSize}</Typography>
                    <img src={image.url} alt="test" title={image.url} width={formData.width} />
                  </Box>
                </Grid.Col>
              ))}
        </Grid.Row>
      </Grid>
    </>
  );
}

export default DeveloperDocs;

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import useDownloader from '../../../node_module_resolver/react-use-downloader';
import {
  Button,
  Group,
  IconArticle,
  IconCheckCircle,
  IconPlusCircle,
  IconUpload,
  IconMinusCircle,
  IconImageGallery,
  IconDownload,
  LoaderBar,
  Token,
  Tooltip,
  Typography,
} from '@screentone/core';

import Image from '../Image';
import {
  getAllPublishedIds,
  getImageUrl,
  getPlaced,
  getImageAspectRatio,
  getRelatedProperties,
  getKeywords,
} from '../../utils/helpers';

import { constants } from '../../utils';

import type { ImageType, PropertyType, EnvironmentType } from '../../types';

import styles from './Gallery.module.css';

type GalleryItemProps = {
  /** information about image. TODO: need to pull from standard type def once API is finalized */
  image: ImageType;
  /** whether this item shows "active" state */
  active?: boolean;
  /** whether this item has been selected */
  isSelected?: boolean;
  isActiveSelected?: boolean;
  /** event handler when image is clicked */
  onClick(e: any): void;
  /** dimensions for displaying this item in the context of the Gallery component */
  dimensions: {
    width: number;
  };
  /** additional options */
  options: {
    /** env  info */
    env: EnvironmentType;
    /** current property  */
    property: PropertyType;
  };
  /** handler when selected */
  onSelect?: (image?: ImageType | null, e?: any) => void;
  /** handler when deselected */
  onDeselect?: (img: ImageType) => void;
};

/**
 * Given an array of images, shows a row of them
 */
const GalleryItem = React.forwardRef(
  (
    {
      image,
      dimensions,
      active = false,
      onClick,
      options,
      isSelected = false,
      isActiveSelected = false,
      onSelect,
      onDeselect,
    }: GalleryItemProps,
    ref: any
  ) => {
    const { env, property } = options;
    const imageUrl = getImageUrl({ image, env, property, defaultPreviewUrl: true });
    const downloadUrl = image.secure_url;
    const downloadFileName = `${image.public_id}.${image.format}`;

    const {
      download: downloadImage,
      percentage: imageDownloadPercentage,
      isInProgress: downloadInProgress,
    } = useDownloader();

    const containerClasses = [styles.item];

    if (active) containerClasses.push(styles['item--active']);
    if (isSelected) containerClasses.push(styles['item--selected']);
    if (isActiveSelected) containerClasses.push(styles['item--active']);

    const imageCallback = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onClick(e);
      },
      [onClick]
    );

    const allPublishedIds = getAllPublishedIds(image, property);
    const isPublished = allPublishedIds.length > 0;
    const hasMultiplePublished = allPublishedIds.length > 1;
    const isUpload = image?.metadata?.import_source_type === 'upload';
    const keywords = getKeywords(property);
    const placed = getPlaced(image, property);
    const relatedProperties = getRelatedProperties(property);

    const imageDetailsHREF = `/${property}/image/${image.asset_id}`;
    return (
      <div
        data-testid="gallery-item"
        ref={ref}
        className={containerClasses.join(' ')}
        style={{ width: `${dimensions.width}%`, aspectRatio: getImageAspectRatio(image, property) }}
      >
        <Link
          to={imageDetailsHREF}
          onClick={imageCallback}
          className={styles.item__a_img}
          style={{ '--im-preview-url': `url(${imageUrl})` } as React.CSSProperties}
        >
          <Image
            alt={image?.metadata?.caption || image?.metadata?.headline || 'Image without caption'}
            src={imageUrl}
            className={styles.item__img}
            draggable={!isSelected}
          />
        </Link>
        {onSelect && onDeselect && (
          <div className={styles.item__select}>
            <Tooltip>
              <Tooltip.Content position="left" id={`tooltip-${image.public_id}`}>
                {isSelected ? 'Remove from Lightbox' : 'Add to Lightbox'}
              </Tooltip.Content>
              <Tooltip.Trigger aria-describedby={`tooltip-${image.public_id}`}>
                {isSelected ? (
                  <button
                    type="button"
                    data-testid="removeFromLightbox-icon"
                    className={[styles.item__selectbtn, 'st_focusable'].join(' ')}
                    onClick={() => onDeselect(image)}
                  >
                    <IconMinusCircle size="md" />
                  </button>
                ) : (
                  <button
                    type="button"
                    data-testid="addToLightbox-icon"
                    className={[styles.item__selectbtn, 'st_focusable'].join(' ')}
                    onClick={(e) => onSelect(image, e)}
                  >
                    <IconPlusCircle size="md" />
                  </button>
                )}
              </Tooltip.Trigger>
            </Tooltip>
          </div>
        )}
        <Group gap="none" className={styles.item__meta} fullWidth align="space-between">
          <Group gap="xs" margin={{ all: 'sm ' }}>
            {!isUpload && isPublished && <Token icon={IconCheckCircle} color="blurple" aria-label="Published" />}
            {isUpload && <Token icon={IconUpload} color="blurple" aria-label="Upload" title="Upload" />}
            {isPublished && hasMultiplePublished && (
              <Token color="tangerine" title="Multiple image sets" icon={IconImageGallery} />
            )}
            {keywords.TAGS &&
              Object.keys(keywords.TAGS).map((tagKey) => {
                const tag = keywords.TAGS[tagKey];
                if (image.tags?.includes(tag.key)) {
                  return (
                    <Token
                      color={tag.color}
                      aria-label={tag.label}
                      title={tag.title}
                      icon={keywords.ICONS[tag.icon as keyof typeof keywords.ICONS]}
                    />
                  );
                }
                return null;
              })}

            {placed &&
              (relatedProperties.length > 0 ? (
                <Tooltip>
                  <Tooltip.Content position="bottom" id="tooltipContent">
                    <Typography weight="bold" size="sm" style={{ color: 'var(--st-color-cool)' }} whiteSpace="nowrap">
                      Used By
                    </Typography>
                    {Object.keys(placed).map((item: string) => {
                      const [value] = item.split(':');
                      return (
                        <Typography key={item} size="sm" style={{ color: 'var(--st-color-cool)' }} whiteSpace="nowrap">
                          {constants.PROPERTY_LABELS[value as keyof typeof constants.PROPERTY_LABELS] || value}
                        </Typography>
                      );
                    })}
                  </Tooltip.Content>
                  <Tooltip.Trigger aria-describedby="tooltipContent">
                    <Token icon={IconArticle} color="gray" aria-label="Used in Article" style={{ cursor: 'pointer' }} />
                  </Tooltip.Trigger>
                </Tooltip>
              ) : (
                <Token icon={IconArticle} color="gray" aria-label="Used in Article" title="Used in Article" />
              ))}
          </Group>
          <Button
            data-testid="download-btn"
            className={styles.item__download_icon}
            margin={{ all: 'sm ' }}
            tertiary
            disabled={downloadInProgress}
            color="white"
            icon={IconDownload}
            componentEl="a"
            href={downloadUrl}
            onClick={(e: Event) => {
              e.preventDefault();
              downloadImage(downloadUrl, downloadFileName);
            }}
          />
        </Group>

        {downloadInProgress && (
          <LoaderBar
            style={{ width: '100%', position: 'absolute' }}
            percent={imageDownloadPercentage}
            displayPercent={false}
            text={null}
          />
        )}
      </div>
    );
  }
);

GalleryItem.displayName = 'GalleryItem';
export default GalleryItem;

{
  "KEY": "ydj",
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": true
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://dj-development-res.cloudinary.com/"
    },
    "dev": {
      "default": "https://images-newswires.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": "https://ydj-images.stg.wsj.net/"
    },
    "prd": {
      "default": "https://ydj-images.wsj.net/"
    }
  },
  "PREVIEW_SIZES": {
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "2:1": {
      "ar": 2,
      "label": "2:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "3:4": {
      "ar": 0.75,
      "label": "3:4"
    },
    "4:3": {
      "ar": 1.33,
      "label": "4:3"
    },
    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    }
  },
  "EAGER": {
    "cldDefault": [],
    "resize": []
  },
  "SLACK": {
    "dev": "C02PRBFBBH7",
    "prd": "C07CP0FKL1G"
  },
  "ALT_SIZES": [
    {
      "key": "1:1",
      "label": "1:1",
      "width": 1280,
      "height": 1280
    },
    {
      "key": "2:1",
      "label": "2:1",
      "width": 1280,
      "height": 640
    },
    {
      "key": "3:2",
      "label": "3:2",
      "width": 1280,
      "height": 853
    },
    {
      "key": "3:4",
      "label": "3:4",
      "width": 1280,
      "height": 1707
    },
    {
      "key": "4:3",
      "label": "4:3",
      "width": 1280,
      "height": 960
    },
    {
      "key": "16:9",
      "label": "16:9",
      "width": 1280,
      "height": 720
    }
  ],
  "DISABLE_THUMBNAILS": true,
  "DEFAULTS": {},
  "COLORS": [],
  "MD5_4_DOMAIN": {
    "local": "c8ec",
    "dev": "c8ec",
    "int": "37ef",
    "prd": "acf5"
  },
  "FORM_VALIDATION": ["headline"],
  "CROP": [
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld"
    }
  },
  "SEARCH_FIELDS": [
    "metadata.headline",
    "metadata.caption",
    "metadata.credit",
    "metadata.contact",
    "context.\"tags_$PROPERTY$\""
  ]
}

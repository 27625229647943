{
  "KEY": "barrons",
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": true
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://images-barrons.dev.djtools.dowjones.io/"
    },
    "dev": {
      "default": "https://images-barrons.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": "https://images.stg.barrons.com/"
    },
    "prd": {
      "default": "https://images.barrons.com/"
    }
  },
  "PREVIEW_SIZES": {
    "1:1:thumb": {
      "isThumbnail": true,
      "ar": 1,
      "label": "Thumb 1:1"
    },
    "3:2:thumb": {
      "isThumbnail": true,
      "ar": 1.5,
      "label": "Thumb 3:2"
    },
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "2:1": {
      "ar": 2,
      "label": "2:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "3:4": {
      "ar": 0.75,
      "label": "3:4"
    },
    "4:3": {
      "ar": 1.33,
      "label": "4:3"
    },
    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    }
  },
  "EAGER": {
    "cldDefault": [
      [
        { "c": "fill", "g": "$G", "h": 840, "w": 1260, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "$G", "h": 333, "w": 500, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "thumb", "g": "$G", "h": 200, "w": 300, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "thumb", "g": "$G", "h": 193, "w": 290, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "thumb", "g": "$G", "h": 147, "w": 220, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ]
    ],
    "resize": []
  },
  "SLACK": {
    "dev": "C02PRBFBBH7",
    "prd": "C02TPR0P9GX"
  },
  "ALT_SIZES": [
    {
      "key": "1:1",
      "label": "1:1",
      "width": 1280,
      "height": 1280
    },
    {
      "key": "2:1",
      "label": "2:1",
      "width": 1280,
      "height": 640
    },
    {
      "key": "3:2",
      "label": "3:2",
      "width": 1280,
      "height": 853
    },
    {
      "key": "3:4",
      "label": "3:4",
      "width": 1280,
      "height": 1707
    },
    {
      "key": "4:3",
      "label": "4:3",
      "width": 1280,
      "height": 960
    },
    {
      "key": "16:9",
      "label": "16:9",
      "width": 1280,
      "height": 720
    }
  ],
  "DISABLE_THUMBNAILS": true,
  "DEFAULTS": {},
  "COLORS": [],
  "MD5_4_DOMAIN": {
    "local": "e5ea",
    "dev": "e5ea",
    "int": "9e03",
    "prd": "2a15"
  },
  "FORM_VALIDATION": ["headline"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "legacy": {
          "label": "Legacy",
          "folder": ":property/smartcrop"
        },
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "TAGS": {
    "SELECTS": {
      "key": "BarronsSelects",
      "label": "Barron's Selects",
      "color": "ice",
      "icon": "star",
      "title": "Image Editorially Selected",
      "editable": true
    },
    "PRINT": {
      "key": "BarronsPrint",
      "label": "Used In Print",
      "color": "gray",
      "icon": "newspaper",
      "title": "Image Used In Print",
      "editable": true
    }
  },
  "ADVANCED_SEARCH": {
    "show_advanced": false,
    "keywords": [
      { "label": "Barron's Selects", "name": "BarronsSelects" },
      { "label": "Used In Print", "name": "BarronsPrint" }
    ]
  },
  "SEARCH_FIELDS": [
    "metadata.headline",
    "metadata.caption",
    "metadata.credit",
    "metadata.contact",
    "context.\"tags_$PROPERTY$\"",
    "context.\"published_labels_$PROPERTY$\""
  ]
}

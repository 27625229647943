{
  "KEY": "pen",
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": false
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://dj-development-res.cloudinary.com/"
    },
    "dev": {
      "default": "https://images-pen.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": "https://images.stg.penews.com/"
    },
    "prd": {
      "default": "https://images.penews.com/"
    }
  },
  "PREVIEW_SIZES": {
    "1:1:thumb": {
      "isThumbnail": true,
      "ar": 1,
      "label": "Thumb 1:1"
    },
    "3:2:thumb": {
      "isThumbnail": true,
      "ar": 1.5,
      "label": "Thumb 3:2"
    },
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "4:3": {
      "ar": 1.33,
      "label": "4:3"
    },
    "2:1": {
      "ar": 2,
      "label": "2:1"
    },
    "2:3": {
      "ar": 0.66,
      "label": "2:3"
    },

    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    }
  },
  "EAGER": {
    "cldDefault": [
      [
        { "c": "fill", "g": "$G", "h": 853, "w": 1280, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "$G", "h": 720, "w": 1280, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "$G", "h": 403, "w": 604, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "thumb", "g": "$G", "h": 200, "w": 300, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "thumb", "g": "$G", "h": 100, "w": 150, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ]
    ],
    "resize": [
      [
        { "c": "pad", "h": 853, "w": 1280, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 720, "w": 1280, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 403, "w": 604, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 200, "w": 300, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 100, "w": 150, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ]
    ]
  },
  "SLACK": {
    "dev": "C02PRBFBBH7",
    "prd": "C02TQAQ5HEK"
  },
  "ALT_SIZES": [
    {
      "key": "1:1",
      "label": "1:1",
      "width": 1280,
      "height": 1280
    },
    {
      "key": "2:1",
      "label": "2:1",
      "width": 1280,
      "height": 640
    },
    {
      "key": "3:2",
      "label": "3:2",
      "width": 1280,
      "height": 853
    },
    {
      "key": "16:9",
      "label": "16:9",
      "width": 1280,
      "height": 720
    },
    {
      "key": "4:5",
      "label": "4:5",
      "width": 1280,
      "height": 1600
    }
  ],
  "DEFAULTS": {},
  "COLORS": [],
  "MD5_4_DOMAIN": {
    "local": "cd7b",
    "dev": "cd7b",
    "int": "b0dc",
    "prd": "cf36"
  },
  "FORM_VALIDATION": ["headline"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "legacy": {
          "label": "Legacy",
          "folder": ":property/smartcrop"
        },
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "SEARCH_FIELDS": [
    "metadata.headline",
    "metadata.caption",
    "metadata.credit",
    "metadata.contact",
    "context.\"tags_$PROPERTY$\"",
    "context.\"published_labels_$PROPERTY$\""
  ]
}

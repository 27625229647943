{
  "KEY": "mansionglobal",
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": false
  },
  "ADDITIONAL_IMAGE_DOMAINS": ["CN"],
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://images-mansionglobal.dev.djtools.dowjones.io/",
      "CN": "http://images-huanyuju.dowjones.io/"
    },
    "dev": {
      "default": "https://images-mansionglobal.dev.djnews.tools/",
      "CN": "http://images-huanyuju.dowjones.io/"
    },
    "int": {
      "default": "https://images.stg.mansionglobal.com/",
      "CN": "http://images.stg.huanyuju.com/"
    },
    "prd": {
      "default": "https://images.mansionglobal.com/",
      "CN": "http://images.huanyuju.com/"
    }
  },
  "PREVIEW_SIZES": {
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "2:1": {
      "ar": 2,
      "label": "2:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "4:5": {
      "ar": 0.8,
      "label": "4:5"
    },
    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    }
  },
  "EAGER": {
    "cldDefault": [
      [
        { "c": "fill", "g": "auto", "w": 1280, "ar": 1.5 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "auto", "w": 1200, "ar": 1.5 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "auto", "w": 900, "ar": 1.5 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "auto", "w": 640, "ar": 1.5 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "auto", "w": 640, "ar": 1.78 },
        { "f": "avif", "q": "auto:eco" }
      ]
    ],
    "resize": [[{}]]
  },
  "SLACK": {
    "dev": "C02PRBFBBH7",
    "prd": "C02TQASFSTH"
  },
  "ALT_SIZES": [
    {
      "key": "horizontal",
      "label": "16:9",
      "width": 1280,
      "height": 720
    },
    {
      "key": "vertical",
      "label": "Vertical",
      "width": 600,
      "height": 900
    }
  ],
  "DEFAULTS": {},
  "COLORS": [],
  "MD5_4_DOMAIN": {
    "local": "7d28",
    "dev": "7d28",
    "int": "46f6",
    "prd": "8bee"
  },
  "FORM_VALIDATION": ["headline"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "legacy": {
          "label": "Legacy",
          "folder": ":property/smartcrop"
        },
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "SEARCH_FIELDS": [
    "metadata.headline",
    "metadata.caption",
    "metadata.credit",
    "metadata.contact",
    "context.\"tags_$PROPERTY$\"",
    "context.\"published_labels_$PROPERTY$\""
  ]
}
